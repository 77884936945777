<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg" style="width:100%">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div
          class="title"
        >和歌山エリアで人気のチケットです。和歌山の魅力的な観光スポットと人気施設が対象し、有効期間で対象施設の中からお好きな３つ施設を選んで楽しめます。また、電子チケットQRコードを提示すると簡単に入場ができます。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●各施設のご利用は1回のみです。同施設を2回ご利用できません。
          <br />●パスの使用期間は購入日から
          <span>90日</span>以内です。
          <br />EX：
          <span>7/1</span>ご購入の場合、
          <span>7/1~9/28</span>使用可能です。
          <br />●パスの有効期間：1つ目の施設をご入場から
          <span>7日間</span>以内です。
          <br />EX：
          <span>7/1</span>から利用開始の場合、有効期間は
          <span>7/7</span>までです。
          <br />●利用可能施設数：参画施設の中から、任意の<span>3施設</span>
          <br />●注意：同オーダーに複枚数をご購入の場合、中の一枚を利用すると、全て同じ
          <span>7日間</span>有効になります。
          <br />●パスの有効期間7日間は購入日から
          <span>90日間</span>以内に限ります。
          <br />EX：
          <span>7/1~9/28</span>使用可能のパスを
          <span>9/27</span>から利用開始された場合、
          有効期間は
          <span>9/27~9/28</span>です。
          <br />●未使用分を払い戻すことはできません。
          <br />●各施設の営業時間・定休日・利用に関する注意事項は下記リンク、
          または施設公式サイトでご確認ください。
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt />
            </div>
          </el-image>
        </div>

        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr />
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='引き換え場所' && !contentItem.noColor">
                      {{cItem}}
                      <br />
                    </span>
                    <a
                      :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]"
                      target="_blank"
                      :href="cItem"
                      v-show="msg.title==='公式サイト' && !contentItem.noColor"
                    >
                      {{cItem}}
                      <br />
                    </a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      headImg: require("@/assets/images/hfwakayama/jp/havefun_title_jp.jpg"),
      loadImg: require("@/assets/images/hfkansai/loading.png"),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfwakayama/01_en.png'),
          title: ['NO PHONE NO LIFEの旅～秘密の和歌山観光～'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '和歌山県和歌山市美園町5丁目12-2 わかちか広場 (観光交流センター)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.wakayamakanko.com/wakayama_asobi/npnl/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['含むもの：スマホ封印袋・、クリアファイル、白地図、専用バック、旅の記念品'] },
                { cid: 2, text: ['体験時間：9:00～17:00 *予約不要'] },
                { cid: 3, text: ['営業時間は事前に公式サイトをご確認ください。<br>※定休日：12月29日～1月3日'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfwakayama/02_en.png'),
          title: ['和歌山ラーメン＋餃子　or　ベジタリアンラーメン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '和歌山県和歌山市美園町5－7－12'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.instagram.com/menyahishio_jrwakayamaekimae/?igshid=NTc4MTIwNjQ2YQ%3D%3D'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間・定休日は変更となる場合がございますので、ご来店前に店舗にご確認ください。<br>※定休日：12月29日～1月3日'] },
                { cid: 2, text: ['ベジタリアンラーメンには餃子が付きません。'] },
                { cid: 3, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfwakayama/03_en.png'),
          title: ['紀州漆器伝統産業会館「うるわし館」館内利用1000割引クーポン '],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒642-0001 和歌山県海南市船尾222番地'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.instagram.com/menyahishio_jrwakayamaekimae/?igshid=NTc4MTIwNjQ2YQ%3D%3D'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['追加のお支払いは現金でお願いいたします。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['漆器蒔絵体験をご希望の方は、事前に電話でお申し込みください。'] },
                { cid: 4, text: ['漆器蒔絵体験費用にはサイズによって異なります。'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfwakayama/04_en.png'),
          title: ['紀三井寺特別拝観券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒641-0012　和歌山県和歌山市紀三井寺1201'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.kimiidera.com/worship/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['含むもの：拝観料、仏殿展望回廊入場料、大光明殿拝観料、往復ケーブルカー運賃'] },
                { cid: 2, text: ['ご祈祷、ご回向、拝観料金または参拝時間それぞれ異なりますので、ホームページでご確認ください。'] },
                { cid: 3, text: ['大光明殿は一部非公開日がございます。'] },
                { cid: 4, text: ['事前に最新の営業情報をホームページでご確認ください。'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfwakayama/05_en.png'),
          title: ['海南市物産観光センター「かいぶつくん」1,000円分利用券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '和歌山県海南市名高５１－２（JR海南駅構内）'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.kainankanko.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['実券の引換は不要です。会計の際にEチケットを提示してください。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfwakayama/06_en.png'),
          title: ['グランビア和歌山 ホテルズカフェ　パスワード「ケーキセット」'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '和歌山県和歌山市友田町5丁目18番地 '],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.granvia-wakayama.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['含むもの：ケーキ、ドリンク'] },
                { cid: 2, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 3, text: ['営業時間は事前に公式サイトをご確認ください。<br>※臨時休業・満席等の事情でご利用いただけなかった場合があります。'] },
                { cid: 4, text: ['商品内容の写真はイメージです。'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfwakayama/07_en.png'),
          title: ['レンタサイクル・E-bike一日利用1,000円OFFクーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '和歌山県東牟婁郡那智勝浦町築地 6-1-4  (那智勝浦観光案内所) '],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://nachikan.jp/activity/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['料金は利用時間、自転車種類によって異なります。'] },
                { cid: 3, text: ['予約なしでご利用いただけます。台数に限りがあるため、確実なご利用を希望される場合は事前に「travel@nachikan.jp」へメールでお問い合わせください。'] },
                { cid: 4, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 5, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfwakayama/08_en.png'),
          title: ['平安衣装着付け体験1,000円OFFクーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '和歌山県東牟婁郡那智勝浦町築地６－１－４　那智勝浦観光案内所','和歌山県東牟婁郡那智勝浦町市野々３０３４－２　大門坂観光案内所'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://nachikan.jp/activity/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['料金差額分の場合、現場でお支払い必要があります。(現金のみ)'] },
                { cid: 3, text: ['大門坂茶屋にコインロッカーはございません。お客様の衣服のみ番号付きの黒バックにてお預かり致します。貴重品の管理は致しかねますので、予めご了承ください。'] },
                { cid: 4, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfwakayama/09_en.png'),
          title: ['「川柳」選べるお食事クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '那智勝浦町築地4-3-27'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://nachikan.jp/restaurant/1580/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['「さんま寿司（吸い物付き）・かつ丼（吸い物付き）」のいずれか、または追加＠500円で名物生まぐろ丼（吸い物付き）を提供 ※現金のみ'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 10,
          img: require('@/assets/images/hfwakayama/10_en.png'),
          title: ['「にぎわい市場」スペシャルランチメニュー'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '和歌山県東牟婁郡那智勝浦町築地7-12 (店: 鮪の脇口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://nigiwaiichiba.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。<br> ※定休日：火曜日'] },
                { cid: 2, text: ['飲食のラストオーダーは15:30です'] },
                { cid: 3, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 11,
          img: require('@/assets/images/hfwakayama/11_en.png'),
          title: ['紀の松島めぐり遊覧船'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '和歌山県東牟婁郡那智勝浦町勝浦442-20 (紀の松島めぐり受付)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['http://kinomatsushima.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['運行状況及び運行ダイヤを事前にHPをご確認ください。<br> ※休日：水曜日 （但し、荒天時及び閑散期、臨時休業有り）'] },
                { cid: 2, text: ['満員になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfwakayama/13_en.png'),
          title: ['SHIRAHAMA KEY TERRACE ホテルシーモア「日帰り入浴」'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒649-2211 和歌山県西牟婁郡白浜町 1821 (ホテルのフロント)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.keyterrace.co.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 2, text: ['満員になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 14,
          img: require('@/assets/images/hfwakayama/14_en.png'),
          title: ['白浜海中展望塔（コーラルプリンセス）入場券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒649-2211 和歌山県西牟婁郡白浜町 1821 (チケット窓口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.keyterrace.co.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。<br>※悪天候により臨時休業する場合がございます。'] },
                ]
            }
          ]
        },
      ]
    };
  },
  created () {
    // this.$router.push('/hfPage?id=HFWakayama&l=jp')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-wakayama-1-week-free-pass/jp', '_self')
  }
};
</script>
  <style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #fffba0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1a1311;
    font-weight: 700;
    margin-top: 0;
    .title {
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span {
        color: #fa6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }

    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color: #ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>